<template>
  <div class="recharge">
    <van-nav-bar :title="$t('newAdd.Recharge')">
      <van-icon
        slot="left"
        name="arrow-left"
        color="#fff"
        @click="$router.back()"
      />
    </van-nav-bar>
    <div class="content-wrap">
      <div class="padding16">
        <div class="content white-back">
          <div class="payNum font12">
            {{$t('newAdd.RechargeAmount')}}：
            <span class="color-red font22">{{ payData.money }}</span>
          </div>
          <div class="top6 flex between width100pre center-ver">
            <div class="font14 address-wrap">
              {{$t('newAdd.depositAddress')}}：
              <span class="font12">{{ payData.url }}</span>
            </div>
            <div class="copy" @click="copy">{{$t('newAdd.copy')}}</div>
          </div>
          <div class="flex center-hor top10">
            <van-image width="19rem" height="19rem" :src="payData.qrimg" />
          </div>
        </div>
        <div class="step-wrap top16">
          <div class="font14">{{$t('newAdd.rechargeSteps')}}：</div>
          <div class="font12 line1 top10">
            <div v-for="(item, index) in steps" :key="index">
              {{ index + 1 }}.{{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed btn-wrap flex">
      <div class="left" @click="confirm">{{$t('newAdd.completePayment')}}</div>
      <div class="right" @click="downloadQr">{{$t('newAdd.rechargeNow')}}</div>
    </div>
  </div>
</template>

<script>
import { Icon, NavBar, Image as VanImage, Toast } from "vant";
import download from "downloadjs";
import {
  RECHARGE_COMMIT,
} from "@/api";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    Toast,
  },
  data() {
    return {
      payData: {},
      steps: [],
    };
  },
  methods: {
    downloadQr() {
      download(this.payData.qrimg, "mycode.png", "image");
    },
    copy() {
      this.$copyText(this.payData.url).then((e) => {
        Toast("Copy successfully");
      });
    },
    confirm() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT({
        id: this.payData.id,
        money: this.payData.money,
        account: this.payData.account,
        isbefore: 1,
      })
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            // Toast
            this.$router.back()
            // window.location.href = r.data.data.url;
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
  created() {
    let payData = this.$route.params.payData;
    if (payData) {
      this.payData = payData;
      this.$cookie.set("qrPayData", JSON.stringify(this.payData));
    } else {
      this.payData = JSON.parse(this.$cookie.get("qrPayData"));
    }
    this.steps = this.payData.step.split(/[0-9]./);
    for (let i = 0; i < this.steps.length; i++) {
      let item = this.steps[i];
      if (item == "") {
        this.steps.splice(i, 1);
      }
    }
  },
  mounted() {},
  destroyed() {
    this.$cookie.remove("qrPayData");
  },
};
</script>

<style lang="less" scoped>
.recharge {
  position: relative;
  font-family: "PingFang-Regular";
  height: 100%;
  .van-nav-bar {
    background-color: #ff0000;

    /deep/ .van-nav-bar__content {
      background-color: #ff0000;
    }
    /deep/.van-nav-bar__title {
      color: #fff;
    }
    button {
      background-color: #ff0000;
      border: none;
      color: #fff;
      font-size: calc(10rem / 16);
    }
    .iconfont {
      font-size: calc(22rem / 16);
      color: #fff;
    }
  }
  .content-wrap {
    width: 100vw;
    min-height: 100vh;
    background-color: #f4f8f9;
    position: relative;
    overflow: hidden;
    .content {
      padding: 1.375rem 0.875rem;
    }
  }
}
</style>

<style scoped>
.top10 {
  margin-top: 0.625rem;
}
.top6 {
  margin-top: 0.375rem;
}
.top16 {
  margin-top: 1rem;
}
.padding16 {
  padding: 1rem;
}
.padding10 {
  padding: 0.625rem;
}
.white-back {
  background-color: #fff;
}
.font12 {
  font-size: 0.75rem;
}
.font14 {
  font-size: 0.875rem;
}
.font16 {
  font-size: 1rem;
}
.font18 {
  font-size: 1.125rem;
}
.font22 {
  font-size: 1.375rem;
}
.color-red {
  color: #ff0000;
}
.flex {
  display: flex;
}
.between {
  justify-content: space-between;
}
.width100pre {
  width: 100%;
}
.address-wrap {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.copy {
}
.center-ver {
  align-items: center;
}
.center-hor {
  justify-content: center;
}
.line1 {
  line-height: 1.1;
}
.fixed {
  position: fixed;
}
.btn-wrap {
  bottom: 0;
  width: 100vw;
}
.btn-wrap > .left,
.right {
  width: 50vw;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.btn-wrap > .left {
  background-color: #3eb3ff;
}
.btn-wrap > .right {
  background-color: #dc1323;
}
</style>